import { Box, Container, Typography } from "@mui/material"
import Section from "../components/Section"

import SocialAndSustainableBusinessStandard from "../assets/social-and-sustainable-business-standard.png"

export default () => {
  return (
    <Section>
      <Container maxWidth="lg" sx={{ padding: { xs: 0, sm: "inherit" } }}>
        <Typography variant="h4" textAlign="center" sx={{ mb: 2 }}>当団体はソーシャル認定企業制度<Box sx={{ color: "primary.main", display: "inline" }}>「S認証」</Box>を受けています。</Typography>
        <img src={SocialAndSustainableBusinessStandard} alt="ソーシャル企業認定制度 S認証" width={369} />
      </Container>
    </Section>
  )
}
