
import React from "react";
import Section from "../components/Section";
import MemberCard from "../components/MemberCard";

import Advisor from "../assets/advisor.webp";

export default () => {
  return (
    <Section
      id="advisor"
      h1="Advisor"
      caption="外部助言・監修"
    >
      <MemberCard
        isRepresentative
        imgSrc={Advisor}
        name="片山 俊大"
        romajiName="Toshihiro Katayama"
        role="Advisor"
        historyList={[
          { text: "一般社団法人スペースポートジャパン共同創業者・理事", }
        ]}
        description={
          <>
            広告会社入社後、幅広い領域のプロジェクトに従事し、2015年より日本政府・地方公共団体のパブリック戦略担当を歴任。<br /><br />
            日本とUAEの宇宙・資源外交に深く携わったことをきっかけに、宇宙関連事業開発に従事。<br /><br />
            2018年、日本にスペースポート（宇宙港）を実現するための業界団体「一般社団法人スペースポートジャパン」を共同創業し、スペースポートによる地方経済の活性化を行っている。<br /><br />
            著書『超速でわかる！宇宙ビジネス』（すばる舎,2021）
          </>
        }
      />
    </Section>
  );
}
