import { Box, Typography, Grid, Card, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';

import DonutsLab from "../assets/donuts-lab.svg";
import HitoIcon from "../assets/hito-icon.svg";
import HitoThumbnail from "../assets/hito-thumbnail.jpg";
import MonoIcon from "../assets/mono-icon.svg";
import MonoThumbnail from "../assets/mono-thumbnail.png";
import GijutsuIcon from "../assets/gijutsu-icon.svg";
import GijutsuThumbnail from "../assets/gijutsu-thumbnail.png";
import OkaneIcon from "../assets/okane-icon.svg";
import OkaneThumbnail from "../assets/okane-thumbnail.jpg";
import EffortX from "../assets/effort-x.svg";

import MonodukuriFieldIcon from "../assets/fields/monodukuri.svg";
import ApuriKaihatsuFieldIcon from "../assets/fields/apurikaihatsu.svg";
import ChihoSouseiFieldIcon from "../assets/fields/chihosousei.svg";
import KankyoFieldIcon from "../assets/fields/kankyo.svg";
import ShokuhinFieldIcon from "../assets/fields/shokuhin.svg";
import ApareruFieldIcon from "../assets/fields/apareru.svg";
import EntameFieldIcon from "../assets/fields/entame.svg";
import FukushiFieldIcon from "../assets/fields/fukushi.svg";
import KenchikuFieldIcon from "../assets/fields/kenchiku.svg";
import KagakuKenkyuFieldIcon from "../assets/fields/kagakukenkyu.svg";

import CtaBtn from "../elements/CtaBtn";
import Section from "../components/Section";

export default () => {
  return (
    <Section
      isDark
      id="what-we-do"
      caption="私たちの取り組み"
      h1="What We Do"
      h2={
        <>
          中高生一人ひとりの<br />
          「やってみたい」を起点に<br />
          <GradientText>教育と社会を繋げるプラットフォーム</GradientText>
        </>
      }
      sx={{ backgroundImage: "linear-gradient(to left, #FFBF571A, #FF7A7C1A)", backgroundColor: "white" }}
    >
      <Box component="a" href="https://lab.e-donuts.com" target="_blank" sx={{ display: 'block', textAlign: 'center' }}>
        <img src={DonutsLab} alt="Donuts Lab" />
      </Box>

      <Typography align="center" sx={{ lineHeight: 2 }}>
        多くの学校では、生徒個別の興味関心を叶えるための様々なリソースが不足しています。
        <br />
        そこで私たちが<HighlightText>学校と地域社会の間に立つ「ラボ」</HighlightText>
        となり、出来るだけ多種多様な
        <HighlightText>「やってみたい」を形にする</HighlightText>
        ため、地域の力を借りながら必要な支援をしています。
      </Typography>

      <Grid container spacing={3} sx={{ mt: 3 }}>
        {efforts.map((effort) => (
          <Grid item xs={12} md={6} key={effort.keyword}>
            <EffortCard {...effort} />
          </Grid>
        ))}
      </Grid>

      <Box sx={{ textAlign: 'center', my: 4 }}>
        <img src={EffortX} alt="" />
      </Box>

      <Card sx={{ p: { xs: 2 }, boxShadow: 1, borderRadius: 2, bgcolor: 'white' }}>
        <Typography variant="h3" fontSize={18} fontWeight="bold" textAlign="center" >
          子どもたちが「やってみたい」と思うことであれば
          <GradientText>領域問わず！</GradientText>
        </Typography>

        <Box
          component="ul"
          sx={{
            mt: 6,
            display: 'grid',
            gridTemplateColumns: {
              xs: 'repeat(2, 1fr)',
              sm: 'repeat(3, 1fr)',
              md: 'repeat(4, 1fr)',
              lg: 'repeat(5, 1fr)',
            },
            gap: { xs: 2, md: 3 },
            alignItems: 'center',
            pl: 0,
          }}
        >
          {fields.map((field) => (
            <Box component="li" key={field.label} sx={{ listStyle: 'none' }}>
              <FieldCard {...field} />
            </Box>
          ))}
        </Box>
        <Typography
          sx={{
            display: 'block',
            textAlign: 'right',
            lineHeight: 1.5,
            mt: 1,
          }}
        >
          etc...
        </Typography>
      </Card>

      <Box sx={{ mt: 6 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Divider sx={{ flexGrow: 1, mr: 2 }} />
          <Typography variant="body2" color="gray" fontWeight="bold" sx={{ flexShrink: 0 }}>
            教育関係者の方へ
          </Typography>
          <Divider sx={{ flexGrow: 1, ml: 2 }} />
        </Box>

        <Typography
          variant="body1"
          textAlign="center"
          mx="auto"
          fontWeight="bold"
          sx={{ lineHeight: 2, fontSize: 16, my: 2 }}
        >
          その他、各学校現場の状況に合わせた伴走も行っています。<br />
          「探究学習を深めたい」「生徒の好奇心にブレーキをかけたくない」<br />
          といった思いをお持ちの方はご相談ください。<br />
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CtaBtn
            href="https://forms.gle/1o3NpQzK4p5sL6YKA"
            color="success"
            size="large"
            width="fit-content"
          >
            <Typography fontSize={18} color="inherit" fontWeight="bold" lineHeight={1.8}>
              教育関係者の方はこちら
            </Typography>
          </CtaBtn>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", mt: 4 }}>
          <Divider sx={{ flexGrow: 1, mr: 2 }} />
          <Typography variant="body2" color="gray" fontWeight="bold" sx={{ flexShrink: 0 }}>
            企業・個人の方へ
          </Typography>
          <Divider sx={{ flexGrow: 1, ml: 2 }} />
        </Box>

        <Typography
          variant="body1"
          textAlign="center"
          mx="auto"
          fontWeight="bold"
          sx={{ lineHeight: 2, fontSize: 16, my: 2 }}
        >
          多様な子どもたちの「やってみたい」を背中押しするには、<br />
          様々な領域の方の関わりが不可欠です。<br />
          中高生の支援や共創に関心のある企業・個人の方はお声がけください。
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'center', }}>
          <CtaBtn
            href="https://forms.gle/ZJZeyCAroEFtNYjMA"
            color="primary"
            size="large"
            width="fit-content"
          >
            <Typography fontSize={18} color="inherit" fontWeight="bold" lineHeight={1.8}>
              企業・個人の方はこちら
            </Typography>
          </CtaBtn>
        </Box>
      </Box>
    </Section >
  );
};


const GradientText = styled('span')(({ theme }) => ({
  backgroundImage: 'linear-gradient(to left, rgba(255, 191, 87, 1), rgba(255, 122, 124, 1))',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  color: 'transparent !important',
  display: 'inline-block',
}));

const HighlightText = styled('strong')(({ theme }) => ({
  textDecoration: 'underline',
  textDecorationColor: '#fde047',
  textDecorationThickness: '8px',
  textUnderlineOffset: '-4px',
  display: 'inline',
  position: 'relative',
}));

// Data arrays
const efforts = [
  {
    keyword: "ヒト",
    icon: HitoIcon,
    thumbnail: HitoThumbnail,
    description: "専門家との対話機会や企業連携の機会の提供",
    example: "リサイクル探究に取り組む生徒に環境分野で活躍する企業を接続",
  },
  {
    keyword: "モノ",
    icon: MonoIcon,
    thumbnail: MonoThumbnail,
    description: "探究実行に必要な設備の提供・シェア",
    example: "3Dプリンターを関西公立7校に無償寄贈",
  },
  {
    keyword: "技術",
    icon: GijutsuIcon,
    thumbnail: GijutsuThumbnail,
    description: "何かを「作る」のに必要なノウハウの提供",
    example: "「使い切れる調味料入れ」を作りたい生徒に3DCADを伝授",
  },
  {
    keyword: "お金",
    icon: OkaneIcon,
    thumbnail: OkaneThumbnail,
    description: "生徒プロジェクト実行に係る活動資金への助成",
    example: "竹製の漁具を作りたい生徒たちに活動助成金を提供",
  },
];

const fields = [
  { label: "モノづくり", icon: MonodukuriFieldIcon },
  { label: "アプリ開発", icon: ApuriKaihatsuFieldIcon },
  { label: "地域創生", icon: ChihoSouseiFieldIcon },
  { label: "環境", icon: KankyoFieldIcon },
  { label: "食品", icon: ShokuhinFieldIcon },
  { label: "アパレル", icon: ApareruFieldIcon },
  { label: "エンタメ", icon: EntameFieldIcon },
  { label: "福祉", icon: FukushiFieldIcon },
  { label: "建築", icon: KenchikuFieldIcon },
  { label: "科学研究", icon: KagakuKenkyuFieldIcon },
];

const EffortCard = ({ keyword, icon, thumbnail, description, example }) => {
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: 2,
        gap: 1,
        alignItems: 'center',
        backgroundColor: 'white',
        boxShadow: 1,
        borderRadius: 2,
      }}
    >
      <Box sx={{
        display: 'flex', flexDirection: 'column', alignItems: 'center', gap:
          1,
      }}>
        <img src={icon} alt="" />
        <Typography variant="h3" fontWeight="bold" fontSize={18} mt={0}>
          <GradientText sx={{ fontSize: 32, lineHeight: 1.5, }}>{keyword}</GradientText>
          で繋ぐ
        </Typography>
      </Box>

      <Box sx={{ width: '100%', position: 'relative' }}>
        <img
          src={thumbnail}
          alt=""
          style={{
            width: '100%',
            height: 'auto',
            aspectRatio: '16/9',
            objectFit: 'cover',
            borderRadius: '12px',
          }}
        />
      </Box>

      <Typography fontWeight="bold" fontSize={16} letterSpacing={0} textAlign="start" lineHeight={1.5}>{description}</Typography>

      <Box
        sx={{
          width: '100%',
          height: '1px',
          background: 'linear-gradient(to left, rgba(255, 191, 87, 1), rgba(255, 122, 124, 1))',
        }}
      />

      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', width: '100%' }}>
        <Box
          sx={{
            width: 48,
            height: 48,
            backgroundColor: '#FFE600',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 1,
            flexShrink: 0,
            fontWeight: 'bold',
          }}
        >
          例
        </Box>
        <Typography fontSize="14px" fontWeight="bold" textAlign="start" lineHeight={1.5}>
          {example}
        </Typography>
      </Box>
    </Card>
  );
};

const FieldCard = ({ label, icon }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 1,
      width: '130px',
    }}
  >
    <Box
      sx={{
        width: 40,
        height: 40,
        borderRadius: 2,
        background: 'linear-gradient(to left, rgba(255, 191, 87, 0.1), rgba(255, 122, 124, 0.1))',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img src={icon} alt="" />
    </Box>
    <Typography
      sx={{
        fontSize: '0.875rem',
        fontWeight: 'bold',
        lineHeight: 1.5
      }}
    >
      {label}
    </Typography>
  </Box>
);
