import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

import Section from "../components/Section";
import ReportCard from "../components/ReportCard";
import MoreBtn from "../elements/MoreBtn";
import Br from "../elements/Br";

export default () => {
  const [reportList, setReportList] = useState([]);

  useEffect(() => {
    fetchItemList();
  }, []);

  async function fetchItemList() {
    const res = await fetch("https://script.google.com/macros/s/AKfycbyu1MCNkEAwVPVHdbQ9PAcI33cA-dcPN6uJKjDqypvThWW4v0mhflQuRclaN1LV6QnlFw/exec", {
      method: "GET",
    });
    if (!res.ok) { return; }
    const { data } = await res.json();
    if (data) { setReportList(data); }
  }

  return (
    <Section
      isDark
      id="report"
      caption="レポート一覧"
      h1="Donuts Report"
      h2={<><span>先進的な探究活動</span>など、<Br />各種レポートを掲載</>}
    >
      <Container maxWidth="lg" sx={{ padding: { xs: 0, sm: "inherit" } }}>
        <Grid container alignItems="center" spacing={3} mt={4}>
          {!reportList.length && (
            <Grid item xs={12}>
              <CircularProgress />
            </Grid>
          )}
          {reportList.map((report, i) => (
            <Grid key={i} item xs={12} sm={6} md={4}>
              <ReportCard {...report} />
            </Grid>
          ))}
          <Grid item xs={12} sm={!!reportList.length ? 6 : 12} md={12}>
            <MoreBtn href="https://note.com/donuts_report" />
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}
