import React, { useState } from "react";
import Container from "@mui/material/Container";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import Section from "../components/Section";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export default () => {
  const initialVisibleCount = newsList.filter(news => news.isVisible).length;
  const [visibleCount, setVisibleCount] = useState(initialVisibleCount);

  const handleShowMore = () => {
    setVisibleCount(newsList.length);
  };

  return (
    <Section
      isDark
      id="news"
      caption="最新情報"
      h1="News"
    >
      <Container maxWidth="lg" sx={{ padding: { xs: 0, sm: "inherit" } }}>
        <List sx={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)", paddingY: 0 }}>
          {newsList.slice(0, visibleCount).map(({ title, date, url }, i) => (
            <ListItem
              key={i}
              divider
              component={url ? "a" : "div"}
              href={url}
              target="_blank"
              sx={{ paddingY: 2, ":hover": { opacity: url ? 0.7 : 1 } }}
            >
              <Stack direction="row" spacing={2} alignItems="center" width="100%">
                <Typography
                  variant="body2"
                  sx={{ fontSize: "0.5rem", color: "#666" }}
                >
                  {date}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "0.7rem", color: "#333", lineHeight: "1.1rem" }}
                >
                  {title}
                </Typography>
                {url && <ChevronRightIcon fontSize="12px" color="darkGrey" sx={{ marginLeft: "auto !important" }} />}
              </Stack>
            </ListItem>
          ))}
        </List>
        {visibleCount < newsList.length && (
          <Typography
            variant="body2"
            sx={{ cursor: "pointer", color: "#007bff", textAlign: "center", marginTop: 2 }}
            onClick={handleShowMore}
          >
            もっと見る
          </Typography>
        )}
      </Container>
    </Section>
  );
}

const newsList = [
  {
    title: "西日本新聞で、北九州市での探究ピッチプログラムの様子が取り上げられました",
    date: "2024/08/05",
    isVisible: true
  },
  {
    title: "教育家庭新聞で、北九州市での探究ピッチプログラムが紹介されました",
    date: "2024/07/19",
    url: "https://www.kknews.co.jp/news/20240719o04",
    isVisible: true
  },
  {
    title: "教育メディア「Edtechzine」で「Donuts-Lab」リリースの記事が紹介されました",
    date: "2024/03/22",
    url: "https://edtechzine.jp/article/detail/10816",
    isVisible: true
  },
  {
    title: "愛媛大学の『見る・知る アントレプログラム』の中で代表のインタビュー動画が公開されました",
    date: "2023/12/30",
    url: "https://www.youtube.com/watch?v=5m6Lhx4GhtU",
    isVisible: true
  },
  {
    title: "当社がソーシャル企業認証「S認証」を受けました",
    date: "2023/12/1",
    url: "https://besocial.jp/company/e-donuts.com/",
    isVisible: true
  },
  {
    title: "当社の取り組みが京都新聞の紙面第一面に掲載されました",
    date: "2023/11/30",
    isVisible: true
  },
  {
    title: "協賛スポンサー企業に株式会社SAKURUG様が加わりました",
    date: "2023/11/06",
    url: "https://presswalker.jp/press/27365",
    isVisible: false
  },
  {
    title: "テラエナジーでんき「ほっと資産」の寄付先団体として認定を受けました",
    date: "2023/10/27",
    url: "https://presswalker.jp/press/26666",
    isVisible: false
  },
  {
    title: "e-donutsの取り組みが朝日新聞の紙面版・デジタル版に掲載されました",
    date: "2023/10/24",
    url: "https://www.asahi.com/articles/ASRBR7G1TRBBPLZB00J.html",
    isVisible: false
  },
  {
    title: "グローバルの3DプリンターメーカーであるPrusa Research社と提携しました",
    date: "2023/10/17",
    url: "https://presswalker.jp/press/25640",
    isVisible: false
  },
  {
    title: "co-mediaに代表のインタビュー記事が掲載されました",
    date: "2023/10/12",
    url: "https://www.co-media.jp/article/24811",
    isVisible: false
  },
  {
    title: "CAMPFIRE社のソーシャルグッド特化型プラットフォーム「GoodMorning」にてクラウドファンディングを開始しました",
    date: "2023/09/22",
    url: "https://camp-fire.jp/projects/view/658658",
    isVisible: false
  },
  {
    title: "マクロミル・博報堂グループのH.M.マーケティングリサーチの協賛により中高探究学習におけるリサーチ活用支援を開始",
    date: "2023/08/18",
    url: "https://presswalker.jp/press/19929",
    isVisible: false
  },
  {
    title: "非営利スタートアップ助成プログラムSoil100の1期生に採択されました",
    date: "2023/07/10",
    url: "https://presswalker.jp/press/17053",
    isVisible: false
  },
]
