import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

export default (props) => {
  const { children, isDark, id, caption, h1, h2, sx } = props;

  return (
    <Box
      id={id}
      bgcolor={isDark ? "grey.main" : undefined}
      display="flex"
      justifyContent="center"
      textAlign="center"
      py={8}
      as="section"
      {...{ sx }}
    >
      <Container maxWidth="md" >
        <Typography variant="caption">{caption}</Typography>
        <Typography variant="h1">{h1}</Typography>
        {!!h2 && (
          <Typography variant="h2">{h2}</Typography>
        )}
        {children}
      </Container>
    </Box>
  );
}
