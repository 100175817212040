
import React from "react";
import { Card, CardActionArea, CardContent, CardMedia, Grid } from "@mui/material";

import Section from "../components/Section";
import QO from "../assets/sponsor-qo.svg";
import Sakurug from "../assets/sponsor-sakurug.svg";

export default () => {
  return (
    <Section
      id="sponsor"
      h1="Sponsor"
      caption="協賛パートナー"
    >
      <Grid container spacing={4} justifyContent="center">
        {sponsorList.map((sponsor) => (
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ height: "100%", display: "flex", alignItems: "center" }}>
              <CardActionArea
                href={sponsor.url}
                target="blank"
              >
                <CardContent>
                  <CardMedia
                    component="img"
                    image={sponsor.imgUrl}
                    alt={sponsor.name}
                    sx={{ height: 32, objectFit: "contain" }}
                  />
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Section>
  );
}

const sponsorList = [
  {
    name: "QO",
    url: "https://www.q4one.co.jp/",
    imgUrl: QO,
  },
  {
    name: "SAKURUG",
    url: "https://sakurug.co.jp",
    imgUrl: Sakurug
  },
];
