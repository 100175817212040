import React from "react";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";

import Section from "../components/Section";
import MemberCard from "../components/MemberCard";

import ayato from "../assets/member-ayato.jpeg";
import ginzo from "../assets/member-ginzo.jpeg";
import yumoto from "../assets/member-yumoto.jpeg";
import koyabashi from "../assets/member-kobayashi.jpeg"
import irie from "../assets/member-irie.jpg"

export default () => {
  return (
    <Section
      isDark
      id="team"
      caption="e-donuts メンバー"
      h1="Team"
    >
      <Container maxWidth="lg" sx={{ padding: { xs: 0, sm: "inherit" } }}>
        <Stack spacing={3} useFlexGap>
          <MemberCard
            isRepresentative
            imgSrc={ayato}
            name="藤原 彪人"
            romajiName="Ayato Fujiwara"
            role="代表理事"
            historyList={[
              { year: 2022, text: "京都大学経済学部 卒業" },
              { year: 2022, text: "A.T. カーニー株式会社 入社" },
              { year: 2023, text: "一般社団法人e-donuts 創業" },
            ]}
            cathcopy={<>"やってみたいけど、できない"<br /><span>子どもたちを応援</span>する機会を。</>}
            description={
              <>
                京都の田舎の母子家庭で育った私は、人一倍大きかった好奇心を満たすには色々なリソースが不足していました。<br /><br />
                しかし、大人になり少しずつ世の中を俯瞰できるようになると、意外と世の中には子どもたちを応援したい組織や個人がたくさんいることを知りました。<br /><br />
                教育現場を外の世界に開くことで、"やってみたいけど、できない" 子どもたちを支援する機会を実現します。
              </>
            }
          />
          <Grid container rowSpacing={3} columnSpacing={1}>
            <Grid item xs={12} md={6}>
              <MemberCard
                imgSrc={ginzo}
                name="静木 銀蔵"
                romajiName="Ginzo Shizuki"
                role="理事"
                historyList={[
                  { year: 2023, text: "京都大学大学院人間環境学研究科 卒業" },
                  { year: 2023, text: "株式会社エムスリー 入社" },
                  { year: 2024, text: "京都大学 大学院 人間環境学研究科 博士課程" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MemberCard
                imgSrc={yumoto}
                name="湯本 航基"
                romajiName="Koki Yumoto"
                role="Engineer"
                historyList={[
                  { year: 2023, text: "東京通信大学 情報マネジメント学部 入学" },
                  { year: 2024, text: "株式会社bestiee 参画" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MemberCard
                imgSrc={koyabashi}
                name="小林 ゆに"
                romajiName="Yuni Kobayashi"
                role="Designer"
                historyList={[
                  { year: 2021, text: "芝浦工業大学 デザイン工学部 入学" },
                  { year: 2024, text: "株式会社bestiee CXO参画" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MemberCard
                imgSrc={irie}
                name="入江 志保"
                romajiName="Shiho Irie"
                role="Intern"
                historyList={[
                  { year: 2022, text: "大阪大学理学部物理学科 入学" },
                  { year: 2022, text: "学生団体SUIT 参画" },
                  { year: 2024, text: "学生団体SUIT 代表" },
                ]}
              />
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </Section>
  );
}
