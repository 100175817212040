import React from 'react';
import { Typography, Box, Divider, Container } from '@mui/material';
import { styled } from '@mui/system';
import Section from '../components/Section';

const KYOTO_SCHOOLS = [
  "京都府立嵯峨野高校",
  "京都府立京都すばる高校",
  "京都府立桂高校",
  "京都府立洛西高校",
  "京都府立洛北高校",
  "京都府立桃山高校",
  "京都市立日吉ヶ丘高校",
  "京都市立塔南・開建高校",
  "京都市立京都工学院高校",
  "京都府立福知山高校附属中学校",
];

const OSAKA_SCHOOLS = [
  "大阪府立三国丘高校",
  "大阪府立夕陽丘高校",
  "大阪府立豊中高校",
  "大阪府立今宮高等学校",
  "大阪府立吹田東高校",
  "大阪府清水谷高校",
  "泉大津市立小津中学校",
];

const SHIGA_SCHOOLS = ["滋賀県立大津高校", "滋賀県立大津商業高校"];

const OTHER_SCHOOLS = [
  "福岡県立八幡高校",
  "広島桜が丘高校",
  "安田女子中学校高等学校",
  "中村中学校・高等学校",
  "島根大学教育学部附属義務教育学校",
];

const GradientText = styled(Typography)(({ theme }) => ({
  background: 'linear-gradient(to right, rgba(54, 142, 210, 1), rgba(54, 192, 210, 1))',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
}));

const SchoolList = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const SchoolSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& h4': {
    flexShrink: 0,
    paddingRight: theme.spacing(1),
  },
}));

export default () => {
  return (
    <Section h1="現在支援している学校数">
      <Container maxWidth="lg" sx={{ padding: { xs: 0, sm: "inherit" } }}>
        <Box sx={{ textAlign: 'center' }}>
          <GradientText variant="h1" component="span" fontWeight="900" sx={{ fontSize: 88 }}>
            30
          </GradientText>
          <GradientText variant="h3" component="span" sx={{ fontSize: 37 }}>
            校〜
          </GradientText>
        </Box>
        <Typography variant="body2" textAlign="center" sx={{ mt: 1 }}>
          （23年5月～24年8月時点）
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', mt: 4, mb: 2 }}>
          <GradientText sx={{ transform: 'rotate(90deg)', mr: 1, fontSize: 16 }}>▶︎</GradientText>
          <GradientText fontWeight="bold" sx={{ fontSize: 16 }}>
            学校例 一部抜粋
          </GradientText>
          <Typography variant="body2" sx={{ ml: 1, fontSize: 16 }}>※略称表記</Typography>
        </Box>

        <SchoolList>
          <SchoolSection>
            <Typography variant="subtitle2">京都</Typography>
            <Divider sx={{ flexGrow: 1, ml: 1 }} />
          </SchoolSection>
          <Typography lineHeight={1.5} textAlign="left">{KYOTO_SCHOOLS.join("、")}</Typography>

          <SchoolSection>
            <Typography variant="subtitle2">大阪</Typography>
            <Divider sx={{ flexGrow: 1, ml: 1 }} />
          </SchoolSection>
          <Typography lineHeight={1.5} textAlign="left">{OSAKA_SCHOOLS.join("、")}</Typography>

          <SchoolSection>
            <Typography variant="subtitle2">滋賀</Typography>
            <Divider sx={{ flexGrow: 1, ml: 1 }} />
          </SchoolSection>
          <Typography lineHeight={1.5} textAlign="left">{SHIGA_SCHOOLS.join("、")}</Typography>

          <SchoolSection>
            <Typography variant="subtitle2">その他</Typography>
            <Divider sx={{ flexGrow: 1, ml: 1 }} />
          </SchoolSection>
          <Typography lineHeight={1.5} textAlign="left">{OTHER_SCHOOLS.join("、")}</Typography>
        </SchoolList>

        <Box sx={{
          mx: 'auto',
          mt: 8,
        }}>
          <GradientText variant="h4" textAlign="center" fontWeight="bold">
            今後関西内外、各県に支援の輪を拡大していきます
          </GradientText>
        </Box>
      </Container>
    </Section>
  );
};
